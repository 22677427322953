import React, { useState } from "react";
import { IIgrGridBaseDirectiveProps, IgrGridEditDoneEventArgs, IgrGridEditEventArgs, IgrGridModule, IgrGridState, IgrHeadSelectorTemplateContext, IgrRowSelectionEventArgs, IgrRowSelectorTemplateContext } from "@infragistics/igniteui-react-grids";
import { IgrGrid, IgrPaginator, IgrColumn, IgrCellTemplateContext } from "@infragistics/igniteui-react-grids";
import { ComponentRenderer, WebGridDescriptionModule } from '@infragistics/igniteui-react-core';
import { IgrActionStrip, IgrGridEditingActions } from "@infragistics/igniteui-react-grids";
import { IgrGridBaseDirective, IgrColumnComponentEventArgs } from '@infragistics/igniteui-react-grids';
import CommonModal from "./modal";
import { Store, setGridData } from "../utils/store";
import Alert from "./alert";
import jsPDF from "jspdf";
import 'jspdf-autotable';
import postReportData from "../api/updateReport";
import PostEditUserList from "../api/userList";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faLocationDot } from "@fortawesome/free-solid-svg-icons";
import "@infragistics/igniteui-react-grids/grids/combined";
import "@infragistics/igniteui-react-grids/grids/themes/light/bootstrap.css";
import './styles.css'
import DeleteOperationAPI from "../api/deleteOperationApi";
import MenuContext from "./contextMenu";
import { CSVDownloader } from "./csvDownloader";
import { UpdateUserDevices } from "../api/updateDevices";
import { AddAccessories, DeleteAccessories, UpdateAccessories } from "../api/accessoryAPI";
import { postEnableUsers } from "../api/enableUsersAPI";
import { UpdateExtraVehicleLotLocation } from "../api/updateLotAPI";

export interface GridAlertsData {
    (errCode: number, errMsg: string, status: string): void;
}

declare module 'jspdf' {
    interface jsPDF {
        autoTable: any;
    }
}

interface Item {
    [key: string]: any;
}

interface Props {
    data: any[];
    report: string;
    headerOps?: string;
    dataHidden?: string[];
    operation?: string;
    clearOperation: () => void;
    colWidth: any[];
}

const mods: any[] = [
    IgrGridModule
];

mods.forEach((m) => m.register());

export default class IgniteGrid extends React.Component<any, any> {
    private grid1: IgrGrid | null = null;
    gridStateRef: any;
    private grid1Ref(r: IgrGrid) {
        this.grid1 = r;
        this.setState({});
    }
    private column1: IgrColumn | undefined

    constructor(props: any) {
        super(props);
        this.grid1Ref = this.grid1Ref.bind(this);
        this.handleClearAllFilters = this.handleClearAllFilters.bind(this);
        this.handelContextMenu = this.handelContextMenu.bind(this);
        this.handleClick = this.handleClick.bind(this);
        this.onColumnInit = this.onColumnInit.bind(this);
        this.generateColumns = this.generateColumns.bind(this);
        this.gridStateRef = React.createRef<IgrGridState>();
    }

    state = {
        contextMenu: null,
        selectedText: '',
        openModal: false,
        title: '',
        errorCode: null,
        errorMessage: '',
        data: {},
        checkedData: false,
        printExecuted: false,
        hasExecuted: false,
        lastExecuted: null,
        alertKey: null,
        gridSettingsExists: false
    }

    public selectedVinValues: string[] = [];
    public selectedValues: string[] = [];
    public Did = sessionStorage.getItem('dealerId');

    private onColumnInit = (grid: IgrGridBaseDirective, args: IgrColumnComponentEventArgs) => {
        let column = args.detail;
        column.formatter = (val: any) => val
    }

    private onRowDelete = (grid: IgrGridBaseDirective, args: IgrGridEditEventArgs) => {
        let dataDelete: any
        if (this.props.report === "userList") {
            dataDelete = args.detail.rowData.id
        } else if (this.props.report === "vehicleStatus") {
            dataDelete = args.detail.rowData.statusID
        } else if (this.props.report === "scannedVehicleNotes") {
            dataDelete = args.detail.rowData.notesId
        } else if (this.props.report === "User Devices") {
            dataDelete = args.detail.rowData.id
        } else if (this.props.report === "accessory") {
            dataDelete = args.detail.rowData.id
        }

        this.deleteData(dataDelete, this.props.report)
    }

    private onCellEditDone = (grid: IgrGridBaseDirective, args: IgrGridEditDoneEventArgs) => {
        let data: any[] = []
        let dataToSend
        if (args.detail.oldValue) {
            if (this.props.report === 'All Vehicles' || this.props.report === 'Missing Vehicles' || this.props.report === 'Scanned Vehicles') {
                const prevScanned = args.detail.oldValue.scanned;
                const newValue = args.detail.rowData.scanned;
                const changes = prevScanned !== newValue;
                if (changes) {
                    data.push(args.detail.rowData)
                    dataToSend = {
                        scan: JSON.stringify(data),
                        bScanned: args.detail.rowData.scanned
                    }
                    this.updateDataInDb(dataToSend, "scanChanges")
                } else {
                    let ValueChanged
                    let newValues = args.detail.rowData;
                    let dealership = localStorage.getItem("dealershipData")
                    if (dealership) {
                        dealership = JSON.parse(dealership)
                    }
                    newValues.dealership = dealership
                    data.push(newValues);
                    const scannedLotLocationChange = args.detail.oldValue.scannedLotLocation;
                    const newValue = args.detail.rowData.scannedLotLocation;
                    const lotLocationChnage = args.detail.oldValue.lotLocation;
                    const newValue1 = args.detail.rowData.lotLocation;
                    const vehicleStatus = args.detail.oldValue.vehicleStatus;
                    const newValue2 = args.detail.rowData.vehicleStatus;
                    const changes = scannedLotLocationChange !== newValue;
                    const changes1 = lotLocationChnage !== newValue1;
                    const changes2 = vehicleStatus !== newValue2;
                    if (changes) {
                        ValueChanged = "ScannedLotLocation:" + args.detail.rowData.scannedLotLocation + ","
                    }
                    if (changes1) {
                        if (ValueChanged === undefined) {
                            ValueChanged = "lotLocation:" + args.detail.rowData.lotLocation + ","
                        } else {
                            ValueChanged = ValueChanged + "lotLocation:" + args.detail.rowData.lotLocation + ","
                        }
                    }
                    if (changes2) {
                        if (ValueChanged === undefined) {
                            ValueChanged = "vehicleStatus:" + args.detail.rowData.vehicleStatus + ","
                        } else {
                            ValueChanged = ValueChanged + "vehicleStatus:" + args.detail.rowData.vehicleStatus + ","
                        }
                    }
                    dataToSend = {
                        vehicle: JSON.stringify(data),
                        ValueChanged: ValueChanged
                    }
                    this.updateDataInDb(dataToSend, "otherChanges")
                }
            } else if (this.props.report === 'userList') {
                const isEqual = JSON.stringify(args.detail.oldValue) === JSON.stringify(args.detail.newValue);
                if (!isEqual) {
                    let updateData = [{
                        'dealershipName': args.detail.newValue.dealershipName,
                        'email': args.detail.newValue.email,
                        'enable': args.detail.newValue.enable,
                        'id': args.detail.newValue.id,
                        'phoneNumber': args.detail.newValue.phoneNumber,
                        'roleName': args.detail.newValue.roleName,
                        'tempPassword': args.detail.newValue.tempPassword,
                        'userName': args.detail.newValue.userName,
                    }]

                    dataToSend = {
                        vehicle: JSON.stringify(updateData)
                    }
                    this.updateDataInDb(dataToSend, "userListChange")
                }
            } else if (this.props.report === 'vehicleStatus') {
                const statusOldValue = args.detail.oldValue.status;
                const statusNewValue = args.detail.newValue.status;
                if (statusNewValue !== statusOldValue) {
                    dataToSend = {
                        vehicle: {
                            status: statusNewValue,
                            statusID: args.detail.oldValue.statusID
                        }
                    }
                    this.updateDataInDb(dataToSend, "vehicleStatusChanges")
                }
            } else if (this.props.report === "scannedVehicleNotes") {
                const isEqual = JSON.stringify(args.detail.oldValue) === JSON.stringify(args.detail.newValue);
                let scannedVehicleNotes
                if (!isEqual) {
                    scannedVehicleNotes = {
                        vin: args.detail.newValue.vin,
                        stockNo: args.detail.newValue.stockNo,
                        notes: args.detail.newValue.notes,
                        username: args.detail.newValue.username,
                        notesId: args.detail.newValue.notesId
                    }
                }
                dataToSend = {
                    scannedVehicleNotes: scannedVehicleNotes
                }
                this.updateDataInDb(dataToSend, "scannedNotesChanged")
            } else if (this.props.report === 'beaconVehiclesReport' || this.props.report === 'beaconKeysReport') {
                const isEqual = JSON.stringify(args.detail.oldValue) === JSON.stringify(args.detail.newValue);
                if (!isEqual) {
                    let dataToSend = {
                        'veh': [JSON.stringify(args.detail.newValue)]
                    }
                    if (this.props.report === 'beaconVehiclesReport') {
                        this.updateDataInDb(dataToSend, 'beaconsVehiclesReportEdited');
                    } else {
                        this.updateDataInDb(dataToSend, 'beaconsKeysReportEdited');
                    }
                }
            } else if (this.props.report === "accessory") {
                const isEqual = JSON.stringify(args.detail.oldValue) === JSON.stringify(args.detail.newValue);
                if (!isEqual) {
                    let dataToSend = args.detail.newValue;
                    this.updateDataInDb(dataToSend, 'updateAccessories')
                }
            } else if (this.props.report === "Extra Vehicles") {
                const isEqual = JSON.stringify(args.detail.oldValue) === JSON.stringify(args.detail.newValue);
                if (!isEqual) {
                    let dataToSend = {
                        vin: args.detail.newValue.vin,
                        lot: args.detail.newValue.scannedLotLocation,
                        stock: args.detail.newValue.stockNo
                    }
                    this.updateDataInDb(dataToSend, 'extraVehicleLotUpdate')
                }
            }
        }
    }

    private onRowAdd = (grid: IgrGridBaseDirective<IIgrGridBaseDirectiveProps>, args: IgrGridEditEventArgs) => {
        if (this.props.report === "userList") {
            let data = args.detail.newValue
            let dealerName = sessionStorage.getItem('dealershipName')
            if (dealerName) {
                data.dealershipName = dealerName
                this.updateDataInDb(args.detail.newValue, 'addUserToList');
            }
        } else if (this.props.report === "scannedVehicleNotes") {
            args.detail.rowData.dealerName = sessionStorage.getItem("userName");
            args.detail.rowData.username = sessionStorage.getItem("userName");
            args.detail.rowData.dealership = sessionStorage.getItem("dealershipName");
            this.updateDataInDb(args.detail.rowData, 'addScannedVehicleNotes');
        } else if (this.props.report === "vehicleStatus") {
            let dataToAdd = {
                'statusId': this.props.data.length + 1,
                'status': args.detail.newValue.status
            }
            this.updateDataInDb(dataToAdd, 'vehicleStatusAdd');
        } else if (this.props.report === "Extra Vehicles") {
            if (args.detail.newValue.scanId === null || args.detail.newValue.scanId === "" || args.detail.newValue.scanId === undefined) {
                args.detail.newValue.scanId = this.props.data.length + 1
            }
            if (args.detail.newValue.scannedDate === undefined) {
                args.detail.newValue.scannedDate = new Date().toISOString()
            } else {
                args.detail.newValue.scannedDate = new Date(args.detail.newValue.scannedDate).toISOString()
            }
            let data = args.detail.newValue
            let dealership = localStorage.getItem("dealershipData")
            if (dealership) {
                dealership = JSON.parse(dealership)
            }
            data.dealership = dealership
            this.updateDataInDb(args.detail.newValue, 'extraVehiclesAdd');
        } else if (this.props.report === "accessory") {
            let data = args.detail.newValue
            this.updateDataInDb(data, 'addAccessories');
        }
    }

    private deleteData = async (data: any, screenName: string) => {
        let res: any
        let dataToDelete: any
        let ep: string = ''
        if (screenName === 'userList') {
            dataToDelete = {
                'vehicle': data
            }
            ep = 'User/GetDelete'
        } else if (screenName === "vehicleStatus") {
            dataToDelete = {
                'id': data
            }
            ep = 'VehicleStatus/DeleteData'
        } else if (screenName === "scannedVehicleNotes") {
            dataToDelete = {
                'id': data
            }
            ep = 'ScannedVehicleNotes/GetDelete'
        } else if (screenName === "User Devices") {
            dataToDelete = {
                'id': data
            }
            ep = 'UserDevices/DeleteDevice'
        } else if (screenName === "accessory") {
            try {
                let res = await DeleteAccessories(data);
                if (res === "Success") {
                    this.handelGridAlertChange(0, 'Data deleted Successfully', 'Success');
                } else {
                    this.handelGridAlertChange(1, 'Failed to delete the data', 'Failure');
                }
            } catch (err) {
                console.log(err);
                this.handelGridAlertChange(1, 'Failure in deleting the data', 'Failure');
            }
        }

        if (screenName !== "accessory") {
            try {
                res = await DeleteOperationAPI(dataToDelete, ep);
                if (res === "Success") {
                    this.handelGridAlertChange(0, 'Data deleted Successfully', 'Success');
                } else {
                    this.handelGridAlertChange(1, 'Failed to delete the data', 'Failure');
                }
            } catch (err) {
                console.log(err);
                this.handelGridAlertChange(1, 'Failure in deleting the data', 'Failure');
            }
        }
    }

    private updateDataInDb = async (data: any, screenName: string) => {
        let res: any;
        if (screenName === "scanChanges") {
            try {
                res = await postReportData('All Vehicles', data)
                this.handelGridAlertChange(0, 'Data updated Successfully', 'Success');
            } catch (err) {
                console.log(err);
                this.handelGridAlertChange(1, 'Failure in updating the data', 'Failure');
            }
        } else if (screenName === "addUserToList") {
            try {
                data.tempPassword = encodeURIComponent(data.tempPassword);
                res = await PostEditUserList(JSON.stringify(data), 'POST');
                if (res === "Success") {
                    this.handelGridAlertChange(0, 'Successfully added a new user', 'Success');
                } else {
                    this.handelGridAlertChange(1, 'Failed to add new user as' + res, 'Failure');
                }
            } catch (err) {
                console.log(err);
                this.handelGridAlertChange(1, 'Failure in updating the data', 'Failure');
            }
        } else if (screenName === "otherChanges") {
            try {
                res = await postReportData('other', data)
                this.handelGridAlertChange(0, 'Data updated Successfully', 'Success');
            } catch (err) {
                console.log(err);
                this.handelGridAlertChange(1, 'Failure in updating the data', 'Failure');
            }
        } else if (screenName === "vehicleStatusAdd") {
            let dataToSend = {
                'vehicle': data
            }
            try {
                res = await postReportData('vehicleStatusAdd', dataToSend);
                if (res === 'Success') {
                    window.location.reload();
                }
                this.handelGridAlertChange(0, 'Data updated successfully', 'Success');
            } catch (err) {
                console.log(err);
                this.handelGridAlertChange(1, 'Failure in updating the data', 'Failure');
            }
        } else if (screenName === "extraVehiclesAdd") {
            let dataToSend = {
                'data': JSON.stringify(data)
            }
            try {
                res = await postReportData('extraVehicleAdd', dataToSend);
                this.handelGridAlertChange(0, 'Data updated successfully', 'Success');
            } catch (err) {
                console.log(err);
                this.handelGridAlertChange(1, 'Failure in updating the data', 'Failure');
            }
        } else if (screenName === "addScannedVehicleNotes" || screenName === 'vehicleStatusChanges' || screenName === 'scannedNotesChanged' || screenName === 'userListChange') {
            try {
                res = await postReportData(screenName, data)
                if (screenName === "userListChange" && res === "Success") {
                    window.location.reload();
                } else {
                    this.handelGridAlertChange(0, 'Data updated Successfully', 'Success');
                }
            } catch (err) {
                console.log(err)
                this.handelGridAlertChange(1, 'Failure in updating the data', 'Failure');
            }
        } else if (screenName === "beaconsVehiclesReportEdited" || screenName === 'beaconsKeysReportEdited') {
            try {
                res = await postReportData(screenName, data)
                console.log(res)
            } catch (err) {
                console.log(err)
            }
        } else if (screenName === "updateDevices") {
            try {
                res = await UpdateUserDevices(data);
                console.log(res);
            } catch (err) {
                console.log(err);
                this.handelGridAlertChange(1, 'Failure in updating the data', 'Failure');
            }
        } else if (screenName === "addAccessories") {
            try {
                res = await AddAccessories(data);
                if (res === "Success") {
                    this.handelGridAlertChange(0, 'Added a new accessory', 'Success');
                } else {
                    this.handelGridAlertChange(1, 'Failure in adding the accessory', 'Failure');
                }
            } catch (err) {
                console.log(err)
                this.handelGridAlertChange(1, res.message, 'Failure');
            }
        } else if (screenName === "updateAccessories") {
            try {
                res = await UpdateAccessories(data);
                if (res === "Success") {
                    this.handelGridAlertChange(0, `Updated accessory with id ${data.id}`, 'Success');
                } else {
                    this.handelGridAlertChange(1, `Failure in updating accessory with id ${data.id}`, 'Failure');
                }
            } catch (err) {
                console.log(err);
                this.handelGridAlertChange(1, res.message, 'Failure');
            }
        } else if (screenName === "userListEnable") {
            try {
                res = await postEnableUsers(JSON.stringify(data));
                if (res === "Success") {
                    this.handelGridAlertChange(0, 'Updated the user list', 'Success');
                } else {
                    this.handelGridAlertChange(1, `Failure in updating accessory with id ${data.id}`, 'Failure');
                }
            } catch (err) {
                console.log(err);
                this.handelGridAlertChange(1, res.message, 'Failure');
            }
        } else if (screenName === "extraVehicleLotUpdate") {
            try {
                res = await UpdateExtraVehicleLotLocation(data.vin, data.lot, data.stock)
                if (res === "Success") {
                    this.handelGridAlertChange(0, 'Updated the user list', 'Success');
                } else {
                    this.handelGridAlertChange(1, `Failure in updating accessory with id ${data.id}`, 'Failure');
                }
            } catch (err) {
                console.log(err);
                this.handelGridAlertChange(1, res.message, 'Failure');
            }
        }
    }

    public handelGridAlertChange = (errCode: number, errMsg: string, status: string) => {
        this.setState({
            errorCode: errCode,
            errorMessage: errMsg,
            alertKey: Date.now(),
        });

        this.setState((prevState: { hasExecuted: any; }) => ({
            hasExecuted: !prevState.hasExecuted,
        }));

        if (status === "Success" || status === "Failure" || status === "Info") {
            this.selectedVinValues = []
            this.selectedValues = []
        }

        this.handleModalClose()
    }

    public handleModalClose = () => {
        const updateState = () => {
            this.setState({
                openModal: false,
            });
        };

        setTimeout(updateState, 10);
    }

    public onRowSelected = (grid: IgrGridBaseDirective<IIgrGridBaseDirectiveProps>, args: IgrRowSelectionEventArgs) => {
        args.detail.newSelection.map(vinValues => {
            if (!this.selectedVinValues.includes(vinValues.vin)) {
                this.selectedVinValues.push(vinValues.vin)
                this.selectedValues.push(vinValues.stockNo);
                window.sessionStorage.setItem("vin", vinValues.vin);
                window.sessionStorage.setItem("stockNo", vinValues.stockNo);
            }
            return null
        })
    }

    public handleClearAllFilters = () => {
        if (this.grid1) {
            this.grid1.clearSort('');
            this.grid1.clearFilter('');
            this.grid1.clearGrouping('');
            this.grid1.clearSearch();
        }
    }

    public handleAddNewRow = () => {
        if (this.grid1) {
            this.grid1.beginAddRowByIndex(0);
        }
    }

    public handleGridSettingsExists = () => {
        this.setState({
            gridSettingsExists: true
        })
    }

    componentDidMount(): void {
        window.addEventListener('clearFilter', this.handleClearAllFilters);
        window.addEventListener('addNewRow', this.handleAddNewRow);
        window.addEventListener('saveGrid', this.handleGridSave);
        window.addEventListener('clearGrid', this.handleGridClear);
        window.addEventListener('gridSettings', this.handleGridSettingsExists)
    }

    componentDidUpdate(prevProps: Readonly<Props>) {
        if (this.props.headerOps === "printReport" || this.props.report === "printReport") {
            this.handleReportPrint();
        }
        if (this.props.dataHidden !== prevProps.dataHidden) {
            this.showHideColumns(this.props.dataHidden)
        }
        if (this.props.operation === "printLabels") {
            this.printLablesData();
        }
        if (this.props.operation === "Export") {
            this.handleExportData();
        }
    }

    componentWillUnmount(): void {
        window.removeEventListener('clearFilter', this.handleClearAllFilters);
        window.removeEventListener('addNewRow', this.handleAddNewRow);
        window.removeEventListener('saveGrid', this.handleGridSave);
        window.removeEventListener('clearGrid', this.handleGridClear);
        window.removeEventListener('gridSettings', this.handleGridSettingsExists);
    }

    public render(): JSX.Element {
        return (
            <div className="w-full h-[calc(100vh-9rem)]">
                {this.state.errorCode !== undefined && this.state.errorCode !== null ? (
                    <div className="absolute top-0 right-0">
                        <Alert
                            key={this.state.alertKey}
                            code={this.state.errorCode}
                            message={this.state.errorMessage}
                        />
                    </div>
                ) : null}

                <CommonModal isOpen={this.state.openModal} title={this.state.title} onAlert={this.handelGridAlertChange} onHideData={''} onClose={this.handleModalClose} />

                <div className="w-full h-[calc(100vh-12em)]" onContextMenu={this.handelContextMenu} onClick={this.handleClick}>
                    <IgrGrid
                        autoGenerate="true"
                        data={this.props.data}
                        id="grid"
                        primaryKey="id"
                        rowEditable="true"
                        displayDensity="Compact"
                        allowFiltering="true"
                        filterMode="ExcelStyleFilter"
                        ref={this.grid1Ref}
                        columnWidth="120px"
                        columnInit={this.onColumnInit}
                        rowDelete={this.props.report === "User Devices" || this.props.report === "userList" || this.props.report === "scannedVehicleNotes" || this.props.report === "vehicleStatus" || this.props.report === "accessory" ? this.onRowDelete : undefined}
                        rowEditDone={this.props.report === "userList" || this.props.report === "scannedVehicleNotes" || this.props.report === "vehicleStatus" || this.props.report === 'beaconVehiclesReport' || this.props.report === 'beaconKeysReport' || this.props.report === 'All Vehicles' || this.props.report === "accessory" || this.props.report === "Extra Vehicles" ? this.onCellEditDone : undefined}
                        rowAdd={this.props.report === 'beaconVehiclesReport' || this.props.report === 'beaconKeysReport' || this.props.report === "accessory" ? this.onRowAdd : undefined}
                        moving="true"
                        className="gridDataContainer h-full w-full"
                        cellSelection="Multiple"
                        columnSelection="Multiple"
                        rowSelection="Multiple"
                        rowSelectorTemplate={this.webGridRowSelectorExcelTemplate}
                        headSelectorTemplate={this.webGridHeaderRowSelectorExcelTemplate}
                        rowSelectionChanging={this.onRowSelected}>
                        <IgrActionStrip>
                            <IgrGridEditingActions
                                editRow={this.props.report === "userList" || this.props.report === "scannedVehicleNotes" || this.props.report === "vehicleStatus" ? true : false}
                                deleteRow={this.props.report === "User Devices" || this.props.report === "userList" || this.props.report === "scannedVehicleNotes" || this.props.report === "vehicleStatus" || this.props.report === "accessory" ? true : false}
                                addRow={this.props.report === "scannedVehicleNotes" || this.props.report === "vehicleStatus" || this.props.report === "Extra Vehicles" || this.props.report === 'beaconVehiclesReport' || this.props.report === 'beaconKeysReport' ? true : false}
                            />
                        </IgrActionStrip>
                        <IgrPaginator
                            perPage="20">
                        </IgrPaginator>
                        <IgrGridState ref={this.gridStateRef}></IgrGridState>
                        {this.generateColumns()}
                    </IgrGrid>
                    {this.state.contextMenu}
                </div>
            </div>
        )
    }

    private getWidthForEachKey = (key: string) => {
        if (!this.props.colWidth) return '150px';
        const column = this.props.colWidth.find((col: { header: string; }) => col.header === key);
        return column ? column.width : '150px';
    }

    private getColumnKeys = () => {
        if (this.props.data.length === 0) {
            return [];
        }

        return Object.keys(this.props.data[0]);
    };

    private generateColumns = () => {
        if (this.props.report === "userList") {
            let columnsToShow: string[];
            const columnKeys = this.getColumnKeys().filter((key) => key !== "dealership" && key !== "dealershipsForDirectors");
            columnsToShow = ['enable', 'userName', 'email', 'roleName', 'dealershipName', 'phoneNumber', 'tempPassword', '', '']
            return columnKeys.map((key, index) => {
                const hidden = !columnsToShow.includes(key);
                let columnContent;
                if (key === "enable") {
                    columnContent = (
                        <IgrColumn
                            key={index}
                            field={key}
                            header={key}
                            width="120"
                            sortable="true"
                            hasSummary="false"
                            editable="true"
                            resizable="true"
                            bodyTemplate={this.webGridBooleanCellTemplate}
                            dataType="Boolean"
                        />
                    );
                } else if (key === "roleName") {
                    columnContent = (
                        <IgrColumn
                            key={index}
                            field={key}
                            header={key}
                            width="140"
                            sortable="true"
                            hasSummary="false"
                            editable="true"
                            resizable="true"
                            inlineEditorTemplate={this.webGridDropdownTemplate}
                        />
                    );
                } else if (key === "dealershipName") {
                    columnContent = (
                        <IgrColumn
                            key={index}
                            field={key}
                            header={key}
                            width="140"
                            sortable="true"
                            hasSummary="false"
                            editable="false"
                            resizable="true"
                            bodyTemplate={this.webGriddealershipTemplate}
                            dataType="String"
                        />
                    );
                } else {
                    columnContent = (
                        <IgrColumn
                            key={index}
                            field={key}
                            header={key}
                            width="140"
                            sortable="true"
                            hasSummary="false"
                            editable="true"
                            resizable="true"
                            hidden={hidden}
                        />
                    );
                }
                return columnContent;
            })
        } else if (this.props.report === "scannedVehicleNotes") {
            let columnsToShow: string[];
            const columnKeys = this.getColumnKeys().filter((key) => key !== "dealership");
            columnsToShow = ['dealerName', 'vin', 'stockNo', 'notes', 'username']
            return columnKeys.map((key, index) => {
                const hidden = !columnsToShow.includes(key);
                let columnContent;
                if (key === "vin") {
                    columnContent = (
                        <IgrColumn
                            key={index}
                            field={key}
                            header={key}
                            width="180"
                            sortable="true"
                            hasSummary="false"
                            editable="true"
                            resizable="true"
                            hidden={hidden}
                        />
                    )
                } else if (key === "stockNo") {
                    columnContent = (
                        <IgrColumn
                            key={index}
                            field={key}
                            header={key}
                            width="180"
                            sortable="true"
                            hasSummary="false"
                            editable="true"
                            resizable="true"
                            hidden={hidden}
                        />
                    )
                } else if (key === "notes") {
                    columnContent = (
                        <IgrColumn
                            key={index}
                            field={key}
                            header={key}
                            width="180"
                            sortable="true"
                            hasSummary="false"
                            editable="true"
                            resizable="true"
                            hidden={hidden}
                        />
                    )
                } else if (key === "dealerName") {
                    columnContent = (
                        <IgrColumn
                            key={index}
                            field={key}
                            header={key}
                            width="180"
                            sortable="true"
                            hasSummary="false"
                            editable="true"
                            resizable="true"
                            hidden={hidden}
                            defaultValue={sessionStorage.getItem("dealershipName")}
                        />
                    )
                } else {
                    columnContent = (
                        <IgrColumn
                            key={index}
                            field={key}
                            header={key}
                            width="180"
                            sortable="true"
                            hasSummary="false"
                            editable="false"
                            resizable="true"
                            hidden={hidden}
                        />
                    )
                }
                return columnContent;
            })
        } else if (this.props.report === "vehicleStatus") {
            let columnsToShow: string[];
            const columnKeys = this.getColumnKeys().filter((key) => key !== "dealership");
            columnsToShow = ['statusID', 'status']
            return columnKeys.map((key, index) => {
                const hidden = !columnsToShow.includes(key);
                let columnContent;
                if (key === "status") {
                    columnContent = (
                        <IgrColumn
                            key={index}
                            field={key}
                            header={key}
                            width="180"
                            sortable="true"
                            hasSummary="false"
                            editable="true"
                            resizable="true"
                            hidden={hidden}
                        />
                    )
                } else {
                    columnContent = (
                        <IgrColumn
                            key={index}
                            field={key}
                            header={key}
                            width="180"
                            sortable="true"
                            hasSummary="false"
                            editable="false"
                            resizable="true"
                            hidden={hidden}
                        />
                    )
                }
                return columnContent
            })
        } else if (this.props.report === "lotHistory" || this.props.report === "lotHistory1") {
            let columnsToShow: string[];
            const columnKeys = this.getColumnKeys().filter((key) => key !== "dealership" && key !== "user");
            columnsToShow = ['Print', 'transDate', 'dealerName', 'vin', 'stockNo', 'operation', 'lotLocation', 'oldLotLocation', 'username']
            return columnKeys.map((key, index) => {
                const hidden = !columnsToShow.includes(key);
                let columnContent;
                if (key === "Print") {
                    columnContent = (
                        <IgrColumn
                            key={index}
                            field={key}
                            header={key}
                            width="180"
                            sortable="true"
                            hasSummary="false"
                            editable="false"
                            resizable="true"
                            hidden={hidden}
                            bodyTemplate={this.webGridPrintCellTemplate}
                        />
                    )
                } else if (key === "transDate") {
                    columnContent = (
                        <IgrColumn
                            key={index}
                            field={key}
                            header={key}
                            width="180"
                            sortable="true"
                            hasSummary="false"
                            editable="false"
                            resizable="true"
                            hidden={hidden}
                            bodyTemplate={this.webGridDateFormaterTemplate}
                        />
                    )
                } else {
                    columnContent = (
                        <IgrColumn
                            key={index}
                            field={key}
                            header={key}
                            width="180"
                            sortable="true"
                            hasSummary="false"
                            editable="false"
                            resizable="true"
                            hidden={hidden}
                        />
                    )
                }
                return columnContent;
            })
        } else if (this.props.report === "User Devices") {
            let columnsToShow: string[];
            const columnKeys = this.getColumnKeys().filter((key) => key !== "dealership" && key !== "user");
            columnsToShow = ['username', 'registeredDeviceID', 'deviceType', 'deviceName', 'lastActive', 'enable', 'physicalInventory']
            return columnKeys.map((key, index) => {
                const hidden = !columnsToShow.includes(key);
                let columnContent;
                if (key === "enable") {
                    columnContent = (
                        <IgrColumn
                            key={index}
                            field={key}
                            header={key}
                            width="180"
                            sortable="true"
                            hasSummary="false"
                            editable="true"
                            resizable="true"
                            hidden={hidden}
                            bodyTemplate={this.webGridBooleanCellTemplate}
                        />
                    );
                } else if (key === "physicalInventory") {
                    columnContent = (
                        <IgrColumn
                            key={index}
                            field={key}
                            header={key}
                            width="180"
                            sortable="true"
                            hasSummary="false"
                            editable="false"
                            resizable="true"
                            hidden={hidden}
                            movable="true"
                            bodyTemplate={this.webGridBooleanCellTemplate}
                        />
                    );
                } else if (key === "lastActive") {
                    columnContent = (
                        <IgrColumn
                            key={index}
                            field={key}
                            header={key}
                            width="180"
                            sortable="true"
                            hasSummary="false"
                            editable="false"
                            resizable="true"
                            hidden={hidden}
                            movable="true"
                            bodyTemplate={this.webGridDateFormaterTemplate}
                        />
                    );
                } else {
                    columnContent = (
                        <IgrColumn
                            key={index}
                            field={key}
                            header={key}
                            width="180"
                            sortable="true"
                            hasSummary="false"
                            editable="false"
                            resizable="true"
                            hidden={hidden}
                        />
                    );
                }
                return columnContent;
            })
        } else if (this.props.report === "beaconVehiclesReport") {
            let columnsToShow: string[];
            const columnKeys = this.getColumnKeys().filter((key) => key !== "dealership");
            columnsToShow = ["beaconIDForVehicles", "make", "model", "vehicleStatus", "stockNo", "vin", "body", "type", "trimLevel", "color", "miles", "arrivalDate", "retailPrice", "lotLocation", "scannedLotLocation", "lastScanned", "qRScannedCount"];

            return columnKeys.map((key, index) => {
                const hidden = !columnsToShow.includes(key);
                let columnContent;
                if (key === "beaconID1ForKeys") {
                    columnContent = (
                        <IgrColumn
                            key={index}
                            field={key}
                            header={key}
                            width="150"
                            sortable="true"
                            hasSummary="false"
                            editable="true"
                            resizable="true"
                            hidden={hidden}
                            dataType="String"
                        />
                    )
                } else {
                    columnContent = (
                        <IgrColumn
                            key={index}
                            field={key}
                            header={key}
                            width="150"
                            sortable="true"
                            hasSummary="false"
                            editable="false"
                            resizable="true"
                            hidden={hidden}
                        />
                    );
                }
                return columnContent;
            })
        } else if (this.props.report === "beaconKeysReport") {
            let columnsToShow: string[];
            const columnKeys = this.getColumnKeys().filter((key) => key !== "dealership");
            columnsToShow = ["beaconID1ForKeys", "beaconID2ForKeys", "make", "model", "vehicleStatus", "stockNo", "vin", "body", "type", "trimLevel", "color", "miles", "arrivalDate", "retailPrice", "lotLocation", "scannedLotLocation", "lastScanned", "qRScannedCount"];

            return columnKeys.map((key, index) => {
                const hidden = !columnsToShow.includes(key);
                let columnContent;
                if (key === "beaconID1ForKeys") {
                    columnContent = (
                        <IgrColumn
                            key={index}
                            field={key}
                            header={key}
                            width="150"
                            sortable="true"
                            hasSummary="false"
                            editable="true"
                            resizable="true"
                            hidden={hidden}
                            dataType="String"
                        />
                    )
                } else if (key === "beaconID2ForKeys") {
                    columnContent = (
                        <IgrColumn
                            key={index}
                            field={key}
                            header={key}
                            width="150"
                            sortable="true"
                            hasSummary="false"
                            editable="true"
                            resizable="true"
                            hidden={hidden}
                            dataType="String"
                        />
                    )
                } else {
                    columnContent = (
                        <IgrColumn
                            key={index}
                            field={key}
                            header={key}
                            width="150"
                            sortable="true"
                            hasSummary="false"
                            editable="false"
                            resizable="true"
                            hidden={hidden}
                        />
                    );
                }
                return columnContent;
            })
        } else if (this.props.report === 'getBeaconKeys') {
            let columnsToShow: string[];
            const columnKeys = this.getColumnKeys().filter((key) => key !== "dealership");
            columnsToShow = ["beaconID1ForKeys", "beaconID2ForKeys", "vin"];

            return columnKeys.map((key, index) => {
                const hidden = !columnsToShow.includes(key);
                let columnContent;
                if (key === "beaconID1ForKeys") {
                    columnContent = (
                        <IgrColumn
                            key={index}
                            field={key}
                            header="BeaconID1"
                            width="150"
                            sortable="true"
                            hasSummary="false"
                            editable="false"
                            resizable="true"
                            hidden={hidden}
                            dataType="String"
                        />
                    )
                } else if (key === "beaconID2ForKeys") {
                    columnContent = (
                        <IgrColumn
                            key={index}
                            field={key}
                            header="BeaconId2"
                            width="150"
                            sortable="true"
                            hasSummary="false"
                            editable="false"
                            resizable="true"
                            hidden={hidden}
                            dataType="String"
                        />
                    )
                } else {
                    columnContent = (
                        <IgrColumn
                            key={index}
                            field={key}
                            header={key}
                            width="150"
                            sortable="true"
                            hasSummary="false"
                            editable="false"
                            resizable="true"
                            hidden={hidden}
                        />
                    );
                }
                return columnContent;
            })
        } else if (this.props.report === "accessory") {
            let columnsToShow = ["name", "description", "accessoryPrice", "type"]
            const columnKeys = this.getColumnKeys().filter((key) => key !== "dealership" && key !== "dealetId");
            return columnKeys.map((key, index) => {
                const hidden = !columnsToShow.includes(key);
                let columnContent;
                if (key === "accessoryPrice") {
                    columnContent = (
                        <IgrColumn
                            key={index}
                            field={key}
                            header={key}
                            width="150"
                            sortable="true"
                            hasSummary="false"
                            editable="true"
                            resizable="true"
                            bodyTemplate={this.webGridNumberCellTemplate}
                            dataType="Number"
                            hidden={hidden}
                        />
                    )
                } else {
                    columnContent = (
                        <IgrColumn
                            key={index}
                            field={key}
                            header={key}
                            width="150"
                            sortable="true"
                            hasSummary="false"
                            editable="true"
                            resizable="true"
                            // bodyTemplate={this.webGridBooleanCellTemplate}
                            // dataType="Boolean"
                            hidden={hidden}
                        />
                    )
                }
                return columnContent;
            })
        } else {
            let columnsToShow: string[];
            if (this.props.report === "Extra Vehicles") {
                columnsToShow = ["add", "vin", "scannedDate", "stockNo", "scannedLotLocation", "color", "vehicleStatus", "lastKnownLocation"]
            } else {
                columnsToShow = ["Print", "lastKnownLocation","stockNo", "type", "modelYear", "make", "model", "color", "vin", "arrivalDate", "retailPrice", "body", "miles", "trimLevel", "age", "lotLocation",  "lastScanned", "vehicleStatus", "scannedLotLocation", "qRScannedCount"];
                columnsToShow = this.makeColumnsHidden(columnsToShow, this.props.hiddenData);
                columnsToShow = this.makeColumnsVisible(columnsToShow, this.props.visibleData);
            }
            let columnKeys: any[]
            if(this.state.gridSettingsExists) { 
                columnKeys = this.getColumnKeys().filter((key) => key !== "dealership");
            } else {
                columnKeys = this.getColumnKeys().filter((key) => key !== "dealership").sort((a, b) => {
                    const indexA = columnsToShow.indexOf(a);
                    const indexB = columnsToShow.indexOf(b);
                    if (indexA === -1 && indexB === -1) return 0; // Both not in columnsToShow
                    if (indexA === -1) return 1; // 'a' is not in columnsToShow, move it to the end
                    if (indexB === -1) return -1; // 'b' is not in columnsToShow, move it to the end
                    return indexA - indexB; // Sort based on their order in columnsToShow
                });
            }
            return columnKeys.map((key, index) => {
                const hidden = !columnsToShow.includes(key);
                const columnWidth = this.getWidthForEachKey(key);
                let columnContent;
                if (key === "Print") {
                    columnContent = (
                        <IgrColumn
                            key={index}
                            field={key}
                            header={key}
                            width={columnWidth}
                            sortable="true"
                            hasSummary="false"
                            editable="false"
                            resizable="true"
                            bodyTemplate={this.webGridPrintCellTemplate}
                            hidden={hidden}
                        />
                    )
                } else if (key === "scanned" || key === "gpsEnable") {
                    columnContent = (
                        <IgrColumn
                            key={index}
                            field={key}
                            header={key}
                            width={columnWidth}
                            sortable="true"
                            hasSummary="false"
                            editable={key === "scanned" ? "true" : "false"}
                            resizable="true"
                            bodyTemplate={this.webGridBooleanCellTemplate}
                            dataType="Boolean"
                            hidden={hidden}
                        />
                    );
                } else if (key === "beaconLastKnownLocation") {
                    columnContent = (
                        <IgrColumn
                            key={index}
                            field={key}
                            header={key}
                            width={columnWidth}
                            sortable="true"
                            hasSummary="false"
                            editable="false"
                            resizable="true"
                            bodyTemplate={this.webGridLocationCellTemplate}
                            movable="true"
                            hidden={hidden}
                        />
                    );
                } else if (key === "lastKnownLocation") {
                    columnContent = (
                        <IgrColumn
                            key={index}
                            field={key}
                            header={key}
                            width={columnWidth}
                            sortable="true"
                            hasSummary="false"
                            editable="false"
                            resizable="true"
                            bodyTemplate={this.webGridLocationCellTemplate}
                            movable="true"
                            hidden={hidden}
                        />
                    );
                } else if (key === "add") {
                    columnContent = (
                        <IgrColumn
                            key={index}
                            field={key}
                            header={key}
                            width={columnWidth}
                            sortable="true"
                            hasSummary="false"
                            editable="false"
                            resizable="true"
                            bodyTemplate={this.webGridAddCellTemplate}
                            hidden={hidden}
                        />
                    )
                } else if (key === "arrivalDate" || key === "lastScanned" || key === "soldDate" || key === "stockedInDate") {
                    columnContent = (
                        <IgrColumn
                            key={index}
                            field={key}
                            header={key}
                            width={columnWidth}
                            sortable="true"
                            hasSummary="false"
                            editable="true"
                            resizable="true"
                            hidden={hidden}
                            bodyTemplate={this.webGridDateFormaterTemplate}
                        />
                    );
                } else if (key === "scannedDate") {
                    columnContent = (
                        <IgrColumn
                            key={index}
                            field={key}
                            header={key}
                            width={columnWidth}
                            sortable="true"
                            hasSummary="false"
                            editable="true"
                            resizable="true"
                            hidden={hidden}
                            bodyTemplate={this.webGridDateFormaterTemplate}
                        />
                    );
                } else if (key === "scannedLotLocation") {
                    columnContent = (
                        <IgrColumn
                            key={index}
                            field={key}
                            header={key}
                            width={columnWidth}
                            sortable="true"
                            hasSummary="false"
                            editable={(this.props.report === "Extra Vehicles" && !this.props.data.scannedLotLocation) || this.props.report === 'All Vehicles' ? "true" : "false"}
                            resizable="true"
                            hidden={hidden}
                            // bodyTemplate={this.webGridScannedLotLocationTemplate}
                            inlineEditorTemplate={this.webGridScannedLotLocationTemplate}
                        />
                    )
                } else if (key === "vehicleStatus") {
                    columnContent = (
                        <IgrColumn
                            key={index}
                            field={key}
                            header={key}
                            width={columnWidth}
                            sortable="true"
                            hasSummary="false"
                            editable={(this.props.report === "Extra Vehicles" && !this.props.data.vehicleStatus) || this.props.report === 'All Vehicles' ? "true" : "false"}
                            resizable="true"
                            hidden={hidden}
                            // bodyTemplate={this.webGridVehicleStatusTemplate}
                            inlineEditorTemplate={this.webGridVehicleStatusTemplate}
                        />
                    )
                } else if (key === "lotLocation") {
                    columnContent = (
                        <IgrColumn
                            key={index}
                            field={key}
                            header={key}
                            width={columnWidth}
                            sortable="true"
                            hasSummary="false"
                            editable="true"
                            resizable="true"
                            hidden={hidden}
                            inlineEditorTemplate={this.webGridLotLocationTemplate}
                        />
                    )
                } else if (key === "vin") {
                    columnContent = (
                        <IgrColumn
                            key={index}
                            field={key}
                            header={key}
                            width={columnWidth}
                            sortable="true"
                            hasSummary="false"
                            editable={this.props.report === "Extra Vehicles" && !this.props.data.vin ? "true" : "false"}
                            resizable="true"
                            hidden={hidden}
                        />
                    )
                } else if (key === "stockNo") {
                    columnContent = (
                        <IgrColumn
                            key={index}
                            field={key}
                            header={key}
                            width={columnWidth}
                            sortable="true"
                            hasSummary="false"
                            editable={this.props.report === "Extra Vehicles" && !this.props.data.stockNo ? "true" : "false"}
                            resizable="true"
                            hidden={hidden}
                        />
                    )
                } else if (key === "scanId" || key === "color" || key === "status" || key === "updateStatus") {
                    columnContent = (
                        <IgrColumn
                            key={index}
                            field={key}
                            header={key}
                            width={columnWidth}
                            sortable="true"
                            hasSummary="false"
                            editable={(this.props.report === "Extra Vehicles" && !this.props.data.scanId) || !this.props.data.color || !this.props.data.status || !this.props.data.updateStatus ? "true" : "false"}
                            resizable="true"
                            hidden={hidden}
                        />
                    )
                } else {
                    columnContent = (
                        <IgrColumn
                            key={index}
                            field={key}
                            header={key}
                            width={columnWidth}
                            sortable="true"
                            hasSummary="false"
                            editable="false"
                            resizable="true"
                            movable="true"
                            hidden={hidden}
                        />
                    );
                }
                return columnContent;
            })
        }
    };

    public webGridRowSelectorExcelTemplate = (e: { dataContext: IgrRowSelectorTemplateContext }) => {
        return <><span style={{ display: "block", width: "30px" }} className="text-center items-center"> {e.dataContext.implicit.index}</span></>;
    }

    public webGridHeaderRowSelectorExcelTemplate = (e: { dataContext: IgrHeadSelectorTemplateContext }) => {
        const ctx = e.dataContext;
        if (ctx.implicit.selectedCount > 0 && ctx.implicit.selectedCount === ctx.implicit.totalCount) {
            return <><span style={{ display: "block", width: "30px" }} className="text-center items-center cursor-pointer"><i style={{ color: "rgb(239, 184, 209)" }}>◢</i></span></>;
        } else {
            return <><span style={{ display: "block", width: "30px" }} className="text-center items-center cursor-pointer"><i>◢</i></span></>;
        }
    };

    public webGridBooleanCellTemplate = (props: { dataContext: IgrCellTemplateContext }) => {
        const [checkedState, setCheckedState] = useState(props.dataContext.cell.value);
        let data: any[] = [];
        let docsToSend: any = {};
        const handleValueChange = () => {
            const isChecked = !props.dataContext.cell.value;
            setCheckedState(!props.dataContext.cell.value)
            const rowData = props.dataContext.cell.row.data;
            let dealershipData = localStorage.getItem("dealershipData");
            if (dealershipData) {
                let dealship = JSON.parse(dealershipData);
                rowData.dealership = dealship
                data.push(rowData)
                if (this.props.report === "User Devices") {
                    this.updateDataInDb(rowData, "updateDevices")
                } else if (this.props.report === "userList") {
                    if (rowData.userName === window.sessionStorage.getItem("userName")) {
                        this.handelGridAlertChange(1, "You cannot disable/enable your own account", "Failure");
                        props.dataContext.cell.editValue = !isChecked;
                        rowData.enable = !isChecked;
                    } else {
                        rowData.enable = isChecked;
                        this.updateDataInDb(rowData, "userListEnable")
                    }
                } else {
                    rowData.scanned = isChecked;
                    docsToSend.scan = JSON.stringify(data)
                    docsToSend.bScanned = isChecked
                    this.updateDataInDb(docsToSend, "scanChanges")
                }
            }
        }
        return (
            <input type="checkbox" checked={checkedState} onChange={() => { handleValueChange() }} />
        );
    }

    public webGridLocationCellTemplate = (props: { dataContext: IgrCellTemplateContext }) => {
        if (props.dataContext.cell.value !== null && typeof (props.dataContext.cell.value) !== "number" && props.dataContext.cell.value !== undefined) {
            const location = props.dataContext.cell.value.split("_");
            let lat = location[0]
            let long = location[1]
            const mapUrl = "https://maps.google.com?q=" + lat + "," + long + "&ll=" + lat + "," + long + "&z=14&t=k"
            return (
                <button onClick={() => window.open(mapUrl, "_blank")}>
                    <FontAwesomeIcon icon={faLocationDot} style={{ color: "#63E6BE" }} size="xl" />
                </button>
            );
        } else {
            return (
                <button>
                    <FontAwesomeIcon icon={faLocationDot} style={{ color: "#FB0430" }} size="xl" />
                </button>
            )
        }
    }

    public webGridPrintCellTemplate = (props: { dataContext: IgrCellTemplateContext }) => {
        const handlePrint = () => {
            this.setState({
                openModal: true,
                title: "Print Data"
            })
            if (this.selectedVinValues.length > 0) {
                Store.dispatch(setGridData(this.selectedVinValues))
            } else {
                const rowData = props.dataContext.cell.row;
                Store.dispatch(setGridData(rowData.data.vin))
            }
        }
        return (
            <button className={props.dataContext.cell.row.data.printCount > 0 ? 'button-11-1' : 'button-11'} onClick={handlePrint}>
                Print
            </button>
        )
    }

    public webGridAddCellTemplate = (props: { dataContext: IgrCellTemplateContext }) => {
        const handleAddClick = () => {
            this.setState({
                openModal: true,
                title: 'Add Vehicles',
            })
            const rowData = props.dataContext.cell.row;
            let addExtraVehicleData = {
                'vin': rowData.data.vin,
                'stockNo': rowData.data.stockNo,
                'color': rowData.data.color,
                'scannedLotLocation': rowData.data.scannedLotLocation
            }
            Store.dispatch(setGridData(addExtraVehicleData))
        }
        return (
            <button className="button-11" onClick={handleAddClick}>
                Add
            </button>
        )
    }

    public webGridDateFormaterTemplate = (props: { dataContext: IgrCellTemplateContext }) => {
        if (props.dataContext.cell.value) {
            var ls = new Date(props.dataContext.cell.value).toLocaleString().split(', ');
            var thedate = new Date(Date.parse(ls[0] + " " + ls[1] + " " + "UTC"));
            return (
                <p className="dateFormatDispay">{thedate.toLocaleString()}</p>
            )
        } else {
            let date = new Date().toLocaleDateString();
            return (
                <p>{date}</p>
            )
        }
    }

    public webGridDropdownTemplate = (props: { dataContext: IgrCellTemplateContext }) => {
        const [usrRole, setUsrRole] = useState(props.dataContext.cell.value)
        const handleUsrRoleChange = (e: any) => {
            setUsrRole(e.target.value);
            props.dataContext.cell.editValue = e.target.value
        }
        if (props.dataContext.cell.value) {
            return (
                <select style={{ width: '150px', border: 'none', background: 'none', fontFamily: 'Montserrat', fontSize: '12px' }} value={usrRole} onChange={(e) => handleUsrRoleChange(e)}>
                    <option selected>{props.dataContext.cell.value ? usrRole : 'Select'}</option>
                    <option value="DealershipManager">Dealership Manager</option>
                    <option value="ScanningAgent">Scanning Agent</option>
                    <option value="User">User</option>
                </select>
            )
        } else {
            return (
                <select style={{ width: '150px', border: 'none', background: 'none', fontFamily: 'Montserrat', fontSize: '12px' }} value={usrRole} onChange={(e) => handleUsrRoleChange(e)}>
                    <option selected>{props.dataContext.cell.value ? usrRole : 'Select'}</option>
                    <option value="DealershipManager">Dealership Manager</option>
                    <option value="ScanningAgent">Scanning Agent</option>
                    <option value="User">User</option>
                </select>
            )
        }
    }

    public webGriddealershipTemplate = (props: { dataContext: IgrCellTemplateContext }) => {
        let name = window.sessionStorage.getItem('dealershipName')
        if (name) {
            props.dataContext.cell.editValue = name;
        } else {
            name = ""
        }
        return (
            <p>{name}</p>
        )
    }

    public webGridScannedLotLocationTemplate = (props: { dataContext: IgrCellTemplateContext }) => {
        const [lotValue, setLotValue] = useState(props.dataContext.cell.value)
        const locations = localStorage.getItem("scannedLotLocations");
        const locationOptions = locations && locations.split(",")
        const handleChange = (e: any) => {
            if (e.target.value === "New Lot Location") {
                handleNewLotLocation()
            } else {
                setLotValue(e.target.value);
                props.dataContext.cell.editValue = e.target.value
            }
        }
        const handleNewLotLocation = () => {
            const newLotValue = prompt('Enter the new lot location for the scanned vehicle');
            setLotValue(newLotValue);
            props.dataContext.cell.editValue = newLotValue
        }
        if (props.dataContext.cell.value) {
            return (
                <select style={{ width: '150px', border: 'none', background: 'none', fontFamily: 'Montserrat', fontSize: '12px' }} value={lotValue} onChange={(e) => { handleChange(e) }}>
                    <option>{props.dataContext.cell.value ? lotValue : ''}</option>
                    {locationOptions && locationOptions.map(location => (
                        <option key={location} value={location}>
                            {location}
                        </option>
                    ))}
                    <option value="New Lot Location">New Lot Location</option>
                </select>
            )
        } else {
            return (
                <select style={{ width: '150px', border: 'none', background: 'none', fontFamily: 'Montserrat', fontSize: '12px' }} value={lotValue} onChange={(e) => { handleChange(e) }}>
                    <option>{lotValue}</option>
                    {locationOptions && locationOptions.map(location => (
                        <option key={location} value={location}>
                            {location}
                        </option>
                    ))}
                    <option value="New Lot Location">New Lot Location</option>
                </select>
            )
        }
    }

    public webGridVehicleStatusTemplate = (props: { dataContext: IgrCellTemplateContext }) => {
        const [vehStatus, setVehStatus] = useState(props.dataContext.cell.value);
        const statuses = localStorage.getItem("statuses");
        const VehicleStatus = statuses && statuses.split(",")
        const handleVehStatusChange = (e: any) => {
            if (e.target.value === "New Status") {
                handleNewVehicleStatus()
            } else {
                setVehStatus(e.target.value);
                props.dataContext.cell.editValue = e.target.value
            }
        }
        const handleNewVehicleStatus = () => {
            const status = prompt('Enter the new status for the vehicle');
            setVehStatus(status);
            props.dataContext.cell.editValue = status
        }
        if (props.dataContext.cell.value) {
            return (
                <select style={{ width: '150px', border: 'none', background: 'none', fontFamily: 'Montserrat', fontSize: '12px' }} value={vehStatus} onChange={(e) => handleVehStatusChange(e)}>
                    <option>{props.dataContext.cell.value ? vehStatus : ''}</option>
                    {VehicleStatus && VehicleStatus.map(vehicleStatus => (
                        <option key={vehicleStatus} value={vehicleStatus}>
                            {vehicleStatus}
                        </option>
                    ))}
                    <option value="New Status">New Status</option>
                </select>
            )
        } else {
            return (
                <select style={{ width: '150px', border: 'none', background: 'none', fontFamily: 'Montserrat', fontSize: '12px' }} value={vehStatus} onChange={(e) => handleVehStatusChange(e)}>
                    <option>{vehStatus}</option>
                    {VehicleStatus && VehicleStatus.map(vehicleStatus => (
                        <option key={vehicleStatus} value={vehicleStatus}>
                            {vehicleStatus}
                        </option>
                    ))}
                    <option value="New Status">New Status</option>
                </select>
            )
        }
    }

    public webGridLotLocationTemplate = (props: { dataContext: IgrCellTemplateContext }) => {
        const [lotLocation, setLotLocation] = useState(props.dataContext.cell.value)
        const locations = localStorage.getItem("lotLocations");
        const locationOptions = locations && locations.split(",")
        const handleNewLocationClick = () => {
            const location = prompt('Enter the new lot location');
            setLotLocation(location);
            props.dataContext.cell.editValue = location
        }
        const handleLotLocationChange = (e: any) => {
            if (e.target.value === "New Location") {
                handleNewLocationClick()
            } else {
                setLotLocation(e.target.value);
                props.dataContext.cell.editValue = e.target.value
            }
        }
        if (props.dataContext.cell.value) {
            return (
                <select style={{ width: '150px', border: 'none', background: 'none', fontFamily: 'Montserrat', fontSize: '12px' }} value={lotLocation} onChange={(e) => handleLotLocationChange(e)}>
                    <option>{props.dataContext.cell.value ? props.dataContext.cell.value : ''}</option>
                    {locationOptions && locationOptions.map(location => (
                        <option key={location} value={location}>
                            {location}
                        </option>
                    ))}
                    <option value="New Location">New Location</option>
                </select>
            )
        } else {
            return (
                <select style={{ width: '150px', border: 'none', background: 'none', fontFamily: 'Montserrat', fontSize: '12px' }} value={lotLocation} onChange={(e) => handleLotLocationChange(e)}>
                    <option>{lotLocation}</option>
                    {locationOptions && locationOptions.map(location => (
                        <option key={location} value={location}>
                            {location}
                        </option>
                    ))}
                    <option value="New Location"> New Location   </option>
                </select>
            )
        }
    }

    public webGridNumberCellTemplate = (props: { dataContext: IgrCellTemplateContext }) => {
        return (
            <p>$ {props.dataContext.cell.value}.00</p>
        )
    }

    private setError = (error: { code: number; message: string; success: string }) => {
        this.handelGridAlertChange(error.code, error.message, error.success)
    }

    private setIsOpen = (isOpen: boolean) => {
        this.setState({
            openModal: isOpen
        })
    }

    private setTitle = (title: string) => {
        this.setState({ title });
    }

    private handelContextMenu = (e: React.MouseEvent<HTMLDivElement>) => {
        e.preventDefault();

        const grid = this.grid1;
        let field = '';
        let vinValue: string = '';
        let model: string = '';
        let stock: string = '';
        let vinNumber: string = '';

        if (grid?.selectedCells) {
            this.selectedValues = [];
            this.selectedVinValues = [];

            grid.selectedCells.forEach(cell => {
                field = cell.column.field;
                this.selectedValues.push(cell.row.data.stockNo);
                this.selectedVinValues.push(cell.row.data.vin);
                vinValue = cell.row.data.id;
                vinNumber = cell.row.data.vin;
                model = cell.row.data.model;
                stock = cell.row.data.stockNo;
            });

            this.setState({
                selectedText: this.selectedValues.join(', ')
            });
        }

        this.setState({
            contextMenu: (
                <div>
                    <MenuContext
                        clientX={e.clientX}
                        clientY={e.clientY}
                        vinData={this.selectedVinValues.join(', ')}
                        stockData={this.selectedValues.join(', ')}
                        vehId={vinValue} setError={this.setError}
                        stockNo={stock} modelData={model} vinNumber={vinNumber}
                        setTitle={this.setTitle} setIsOpen={this.setIsOpen}
                    />
                </div>
            )
        });
    }

    private handleClick = () => {
        this.setState({ contextMenu: null })
    }

    private _componentRenderer: ComponentRenderer | null = null
    public get renderer(): ComponentRenderer {
        if (this._componentRenderer == null) {
            this._componentRenderer = new ComponentRenderer();
            var context = this._componentRenderer.context;
            WebGridDescriptionModule.register(context);
        }
        return this._componentRenderer;
    }

    private handleGridSave = () => {
        this.setState({
            openModal: true,
            title: 'saveGrid',
        })
        const grid = this.gridStateRef.current
        const state = grid?.getStateAsString([]);
        let gridData = JSON.parse(state)
        Store.dispatch(setGridData(gridData.columns))
    }

    private handleGridClear = () => {
        this.setState({
            openModal: true,
            title: 'clearGrid',
        })
    }

    private handleReportPrint = () => {
        if (this.state.printExecuted) {
            return
        }
        this.setState({
            printExecuted: true
        })
        const grid = this.gridStateRef.current
        const state = grid?.getStateAsString([]);
        let gridData = JSON.parse(state);
        const dataToUse = this.selectedValues.length > 0 ? this.selectedValues : this.props.data;
        var doc = new jsPDF({
            orientation: 'l',
            unit: 'in',
            format: [20, 50],
            compress: true
        });
        // Create map of field -> hidden
        const hiddenFields = gridData.columns.reduce((map: { [x: string]: boolean; }, col: { hidden: any; field: string | number; }) => {
            if (col.hidden) map[col.field] = true;
            return map;
        }, {});

        // Filter this.props.data to remove hidden fields
        let modifiedData = dataToUse.map((item: Item) => {
            let newItem: Item = {};
            for (let key in item) {
                if (!hiddenFields[key]) {
                    newItem[key] = item[key];
                }
            }
            return newItem;
        });

        let rows = Object.keys(modifiedData[0]);
        rows = rows.filter(key => key !== "dealership" && key !== "Print");

        const cols = modifiedData.map((item: Item) => {
            let row: Item = [];
            rows.forEach(key => {
                if (key in item) {
                    row.push(item[key]);
                }
            });
            return row;
        });

        doc.autoTable(rows, cols, {
            styles: { fontSize: 20 },
            startY: 30
        });
        // let finalY = doc.previousAutoTable.finalY;
        doc.deletePage(1);
        doc.autoPrint();
        var string = doc.output('datauristring');
        var iframe = "<iframe width='100%' height='100%' src='" + string + "'></iframe>"
        var x = window.open();
        x?.document.open();
        x?.document.write(iframe);
        x?.document.close();
    }

    private makeColumnsVisible = (array1: string[], array2: string[]) => {
        array1.forEach((item: string) => {
            if (!array2.includes(item)) {
                array2.push(item)
            }
        })
        return array2;
    }

    private makeColumnsHidden = (array1: string[], array2: string[]) => {
        for (let i = array1.length - 1; i >= 0; i--) {
            const column = array1[i];
            if (array2.includes(column)) {
                array1.splice(i, 1);
            }
        }
        return array1;
    }

    public showHideColumns(data: any) {
        const grid = this.grid1;
        if (grid) {
            const printColumn = grid.getColumnByName(data.key);
            if (printColumn) {
                printColumn.hidden = data.hidden;
            }
        }
    }

    public printLablesData() {
        if (!this.state.hasExecuted) {
            this.setState({ hasExecuted: true }, () => {
                if (this.selectedVinValues.length === 0) {
                    this.setState({
                        errorCode: 3,
                        errorMessage: 'Please select at least one label'
                    });
                } else {
                    Store.dispatch(setGridData(this.selectedVinValues));
                    this.setState({
                        openModal: true,
                        title: "Print Data"
                    });
                }

                // Reset hasExecuted after a short delay
                setTimeout(() => {
                    this.setState({ hasExecuted: false });
                    this.props.clearOperation();
                }, 100);
            });
        }
    }

    private handleExportData = async () => {
        if (this.Did) {
            switch (this.props.report) {
                case "All Vehicles":
                case "Missing Vehicles":
                case "Scanned Vehicles":
                    if (this.selectedVinValues.length === 0) {
                        CSVDownloader(parseInt(this.Did), 'all', this.props.report);
                        this.props.clearOperation();
                    } else {
                        CSVDownloader(parseInt(this.Did), this.selectedVinValues.join('_'), this.props.report);
                        this.props.clearOperation();
                    }
                    break;
                default:
                    // Do something if report is not one of the specified values
                    break;
            }
        }
    }
}