import { makeAPIRequest } from "./makeRequest";

export const UpdateExtraVehicleLotLocation = (vin: string, lot: string, stock: string) => {
    let config = {
        method: 'post',
        url: process.env.REACT_APP_SERVER_URL + 'ExtraVehicle/UpdateScannedLotLocation?vin=' + vin + '&stock=' + stock + '&lot=' + lot,
        headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/json'
        }
    }

    let res = makeAPIRequest(config);
    return res
}