import React, { useState, useEffect, useCallback } from 'react'
import { Accordion, AccordionItem, AccordionItemHeading, AccordionItemButton, AccordionItemPanel } from 'react-accessible-accordion';
import 'react-accessible-accordion/dist/fancy-example.css';
import './styles.css'
import GetModal from '../api/getModal';
import { Store, GridDataState, setGridData } from '../utils/store';
import { useSelector } from 'react-redux';
import { AlertsData } from './dashboard';
import CommonModal from '../components/modal';

interface Props {
    onAlertChnage: AlertsData
    onClose: () => void;
}

const AddVehicles = ({ onAlertChnage, onClose }: Props) => {
    const gridData = useSelector((state: GridDataState) => state.gridData);
    let vin = gridData.gridData.vin;
    let stock = gridData.gridData.stockNo;
    let color = gridData.gridData.color;
    let scannedLotLocation = gridData.gridData.scannedLotLocation;

    const [vinNumber, setVinNumber] = useState<string>();
    const [stockNumber, setStockNumber] = useState<string>('');
    const [colorData, setColorData] = useState<string>('');
    const [year, setYear] = useState<string>('');
    const [make, setMake] = useState<string>('');
    const [model, setModel] = useState<string>('');
    const [trim, setTrim] = useState<string>('');
    const [fuelType, setFuelType] = useState<string>('');
    const [listPrice, setListPrice] = useState<string>('');
    const [vehCost, setVehCost] = useState<string>('');
    const [lot, setLot] = useState<string>('');
    const [truck, setTruck] = useState<string>('');
    const [grossWt, setGrossWt] = useState<string>('');
    const [odo, setOdo] = useState<string>('');
    const [inspMonth, setInspMonth] = useState<string>('');
    const [engine, setEngine] = useState<string>('');
    const [bodyStyle, setBodyStyle] = useState<string>('');
    const [trans, setTrans] = useState<string>('');
    const [colorCode, setColorCode] = useState<string>('');
    const [engineCode, setEngineCode] = useState<string>('');
    const [trunkKeyCode, setTrunkKeyCode] = useState<string>('');
    const [keylessCode, setKeylessCode] = useState<string>('');
    const [radioCode, setRadioCode] = useState<string>('');
    const [wheellockCode, setWheellockCode] = useState<string>('');
    const [dealerCode, setDealerCode] = useState<string>('');
    const [inventoryDate, setInventoryDate] = useState<Date>();
    const [serviceDate, setServiceDate] = useState<Date>();
    const [salesAccount, setSalesAccount] = useState<string>('');
    const [inventoryAccount, setInventoryAccount] = useState<string>('');
    const [warranty, setWarranty] = useState<number>(0);
    const [miles, setMiles] = useState<number>(0);
    const [deductible, setDeductible] = useState<number>(0);
    const [demoName, setDemoName] = useState<string>('');
    const [companyID, setCompanyID] = useState<string>('');
    const [vehType, setVehType] = useState<string>('');
    const [companyIdList, setcompanyIdList] = useState<string[]>([]);
    const [engineList, setEngineList] = useState<number[]>([]);
    const [transmissionList, setTransmissionList] = useState<string[]>([]);
    const [fuelList, setFuelList] = useState<string[]>([]);
    const [bodyList, setBodyList] = useState<string[]>([]);
    const [modalIsOpen, setModalIsOpen] = useState<boolean>(false);
    const [modalTitle, setModalTitle] = useState<string>('');
    let Did = sessionStorage.getItem('dealerId')

    const fetchData = useCallback(async () => {
        if (vin || stock || color) {
            setVinNumber(vin);
            setColorData(color);
            let data = await GetModal('GET', 'DealerShip/DMSCreate?dealerIdSel=' + vin + ';' + scannedLotLocation + ';' + color + ';' + stock + '-ListOfVehiclesdata', '');
            if (data) {
                setVinNumber(data.vin);
                setStockNumber(data.stockNo);
                setColorData(data.color);
                setYear(data.modelyear);
                setMake(data.make);
                setModel(data.model);
                setTrim(data.trim);
                setFuelType(data.fuelTType);
                setListPrice(data.listPrice);
                setVehCost(data.vehicleCost);
                setLot(data.lot);
                setTruck(data.truck);
                setGrossWt(data.grossWeight);
                setOdo(data.odometer);
                setInspMonth(data.inspectionMonth);
                setEngine(data.engine);
                setBodyStyle(data.style);
                setTrans('');
                setColorCode(data.colorCode);
                setEngineCode(data.engineCode);
                setTrunkKeyCode(data.trunkKeyCode);
                setKeylessCode(data.keylessCode);
                setRadioCode(data.radioCode);
                setWheellockCode(data.wheelLockCode);
                setDealerCode(data.dealerCode);
                setInventoryDate(new Date(data.dateInInventory));
                setServiceDate(new Date(data.dateInService));
                setSalesAccount(data.saleAccount);
                setInventoryAccount(data.inventoryAccount);
                setWarranty(data.warrantyMonths || 0);
                setMiles(data.warrantyMiles || 0);
                setDeductible(data.warrantyDeduct || 0);
                setDemoName(data.demoName);
                setCompanyID(data.companyid);
                setTransmissionList(data.listOfTransmissions);
                setEngineList(data.listOfEngines);
                setFuelList(data.listOfFuelTypes);
                setBodyList(data.listOfBodyStyles);
                setcompanyIdList(data.listOfCompanyIds);
            }
        }
    }, [vin, stock, color])

    useEffect(() => {
        fetchData()
    }, [fetchData])

    const responseData = () => {
        let dataToSend = {
            "DealerId": Did,
            "Vin": vinNumber,
            "Type": vehType,
            "Make": make,
            "Model": model,
            "ModelYear": year,
            "Description": '',
            "StockNo": stockNumber,
            "Companyid": companyID,
            "SalesGroupTxT": null,
            "SalesGroup": null,
            "Style": bodyStyle,
            "Trim": trim,
            "Engine": engine,
            "FuelType": fuelType,
            "FuelTType": '',
            "Transmission": trans,
            "Color": colorData,
            "Lot": lot,
            "Mpg": null,
            "GLApplied": null,
            "VehicleCost": vehCost,
            "ListPrice": listPrice,
            "FourWheelDrive": null,
            "Turbo": null,
            "GrossWeight": grossWt,
            "CertifiedUsedCar": null,
            "Odometer": odo,
            "OdometerActual": null,
            "InspectionMonth": inspMonth,
            "ColorCode": colorCode,
            "EngineCode": engineCode,
            "IgnitionKeyCode": null,
            "TrunkKeyCode": trunkKeyCode,
            "KeylessCode": keylessCode,
            "RadioCode": radioCode,
            "WheelLockCode": wheellockCode,
            "DealerCode": dealerCode,
            "DateInInventory": inventoryDate?.toISOString(),
            "DateInService": serviceDate?.toISOString(),
            "SaleAccount": salesAccount,
            "InventoryAccount": inventoryAccount,
            "WarrantyMonths": warranty.toString(),
            "WarrantyMiles": miles.toString(),
            "WarrantyDeduct": deductible.toString(),
            "DemoName": demoName,
            "LicenseNumber": null,
            "WorkInProcess": null,
            "OptionCode": null,
            "Truck": truck,
            "OptionNumber": null,
            "FieldType": null,
            "AlphaFieldValue": null,
            "NumericFieldValue": null,
            "DateFieldValue": new Date().toISOString(),
            "AddToCostFlag": null
        }

        return dataToSend
    }

    const validateRequiredFields = () => {
        const errors: string[] = [];
        if(!stockNumber.trim()) {
            errors.push('Stock Number is required');
        }

        if(!vehType) {
            errors.push("Vehicle type is required");
        }

        if(!companyID) {
            errors.push("Company ID is required");
        }

        if(engineList.length > 0 && !engine) {
            errors.push("Engine Type is required");
        }

        if(!trans) {
            errors.push("Transmission type is required");
        }

        if(fuelList.length > 0 && !fuelType) {
            errors.push("Fuel Type is required");
        }

        return errors;
    }

    const sendData = async () => {
        const validationErrors = validateRequiredFields();

        if(validationErrors.length > 0) {
            sendResponse(1, validationErrors.join(', '), 'Validation Error');
            return;
        }

        let dataToSend = responseData();
        try {
            let res = await GetModal('POST', 'DealerShip/ChromeVehicleDataFromReact', { 'chromeData': dataToSend })
            if(res === "Success" || res === "success" || res === "Vehicle successfully added") {
                sendResponse(0, 'Vehicle data added successfully', 'Success');
            } else {
                sendResponse(1, res, "Failure");
            }
        } catch (err: any) {
            console.log(err)
            sendResponse(1, err.message, 'Failure')
        }
    }

    const sendResponse = (errCode: number, errMsg: string, status: string) => {
        onAlertChnage(errCode, errMsg, status)
    }

    const printData = () => {
        setModalIsOpen(true);
        setModalTitle('ExtraVehPrint');
        let printDataObject = responseData()
        Store.dispatch(setGridData(printDataObject))
    }

    return (
        <div className='h-[80vh] overflow-y-scroll p-4'>
            <div className="modalTop-body">
                <p className="headerText text-xl text-center">Vehicle details</p>
                <p className="mt-2 headerText text-sm font-bold">Please fill out the following required fields</p>
                <div className="flex justify-between items-center mt-2">
                    <p className="sampleLabel">Stock number</p>
                    <input className="textInput" type="text" required value={stockNumber} onChange={(e) => { setStockNumber(e.target.value) }} />
                </div>
                <div className="flex justify-between items-center mt-2">
                    <p className="sampleLabel">Type</p>
                    <select className="textInput" required value={vehType ? vehType : ''} onChange={(e) => { setVehType(e.target.value) }} >
                        <option>Select</option>
                        <option value="New">New</option>
                        <option value="Used">Used</option>
                    </select>
                </div>
                <div className="flex justify-between items-center mt-2">
                    <p className="sampleLabel">Company ID</p>
                    <select className="textInput" value={companyID ? companyID : ''} onChange={(e) => setCompanyID(e.target.value)}>
                        <option>Select Company Id</option>
                        {companyIdList.map((company, index) => (
                            <option key={index} value={company}>{company}</option>
                        ))}
                    </select>
                </div>
            </div>
            <div className="modal-body">
                <p className="mt-3 headerText mb-3 text-sm font-bold">Non Required Fields</p>
                {/* Accordians */}
                <Accordion>
                    <AccordionItem>
                        <AccordionItemHeading>
                            <AccordionItemButton>
                                Derived From VIN
                            </AccordionItemButton>
                        </AccordionItemHeading>
                        <AccordionItemPanel>
                            <div className="flex justify-between items-center mt-2">
                                <p className="sampleLabel">VIN</p>
                                <input className="textInput" type="text" value={vinNumber} onChange={(e) => { setVinNumber(e.target.value) }} placeholder='Enter the Vin Number of the vehicle' />
                            </div>
                            <div className="flex justify-between items-center mt-2">
                                <p className="sampleLabel">Year</p>
                                <input className="textInput" type="number" value={year} onChange={(e) => setYear(e.target.value)} />
                            </div>
                            <div className="flex justify-between items-center mt-2">
                                <p className="sampleLabel">Make</p>
                                <input className="textInput" type="text" value={make} onChange={(e) => setMake(e.target.value)} />
                            </div>
                            <div className="flex justify-between items-center mt-2">
                                <p className="sampleLabel">Model</p>
                                <input className="textInput" type="text" value={model} onChange={(e) => { setModel(e.target.value) }} />
                            </div>
                            <div className="flex justify-between items-center mt-2">
                                <p className="sampleLabel">Trim</p>
                                <input className="textInput" type="text" value={trim} onChange={(e) => { setTrim(e.target.value) }} />
                            </div>
                            <div className="flex justify-between items-center mt-2">
                                <p className="sampleLabel">Fuel Type</p>
                                <select value={fuelType ? fuelType : ''} onChange={(e) => { setFuelType(e.target.value) }} className="textInput">
                                    <option>Select Fuel Type</option>
                                    {fuelList.map((fuelData, index) => (
                                        <option key={index} value={fuelData}>{fuelData}</option>
                                    ))}
                                </select>
                            </div>
                            <div className="flex justify-between items-center mt-2">
                                <p className="sampleLabel">List Price</p>
                                <input className="textInput" type="number" value={listPrice} onChange={(e) => setListPrice(e.target.value)} />
                            </div>
                            <div className="flex justify-between items-center mt-2">
                                <p className="sampleLabel">Vehicle Cost</p>
                                <input className="textInput" type="number" value={vehCost} onChange={(e) => setVehCost(e.target.value)} />
                            </div>
                        </AccordionItemPanel>
                    </AccordionItem>
                    <hr />
                    <AccordionItem>
                        <AccordionItemHeading>
                            <AccordionItemButton>
                                Vehicle Details
                            </AccordionItemButton>
                        </AccordionItemHeading>
                        <AccordionItemPanel>
                            <div className="flex justify-between items-center mt-2">
                                <p className="sampleLabel">Color</p>
                                <input className="textInput" type="text" value={colorData} onChange={(e) => { setColorData(e.target.value) }} />
                            </div>
                            <div className="flex justify-between items-center mt-2">
                                <p className="sampleLabel">Lot</p>
                                <input className="textInput" type="text" value={lot} onChange={(e) => { setLot(e.target.value) }} />
                            </div>
                            <div className="flex justify-between items-center mt-2">
                                <p className="sampleLabel">Truck</p>
                                <input className="textInput" type="text" value={truck} onChange={(e) => { setTruck(e.target.value) }} />
                            </div>
                            <div className="flex justify-between items-center mt-2">
                                <p className="sampleLabel">Gross weight</p>
                                <input className="textInput" type="text" value={grossWt} onChange={(e) => setGrossWt(e.target.value)} />
                            </div>
                            <div className="flex justify-between items-center mt-2">
                                <p className="sampleLabel">Odometer</p>
                                <input className="textInput" type="text" value={odo} onChange={(e) => { setOdo(e.target.value) }} />
                            </div>
                            <div className="flex justify-between items-center mt-2">
                                <p className="sampleLabel">Inspection Month</p>
                                <input className="textInput" type="text" value={inspMonth} onChange={(e) => setInspMonth(e.target.value)} />
                            </div>
                            <div className="flex justify-between items-center mt-2">
                                <p className="sampleLabel">Engine</p>
                                <select className="textInput" value={engine ? engine : ''} onChange={(e) => setEngine(e.target.value)}>
                                    <option>Select Engine</option>
                                    {engineList.map((engineData, index) => (
                                        <option key={index} value={engineData}>{engineData}</option>
                                    ))}
                                </select>
                            </div>
                            <div className="flex justify-between items-center mt-2">
                                <p className="sampleLabel">Body Style</p>
                                <select className="textInput" value={bodyStyle ? bodyStyle : ''} onChange={(e) => setBodyStyle(e.target.value)}>
                                    <option>Select Body Style</option>
                                    {bodyList.map((bodyData, index) => (
                                        <option key={index} value={bodyData}>{bodyData}</option>
                                    ))}
                                </select>
                            </div>
                            <div className="flex justify-between items-center mt-2">
                                <p className="sampleLabel">Transmission Code</p>
                                <input className="textInput" type="text" value={trans} onChange={(e) => setTrans(e.target.value)} />
                            </div>
                        </AccordionItemPanel>
                    </AccordionItem>
                    <hr />
                    <AccordionItem>
                        <AccordionItemHeading>
                            <AccordionItemButton>
                                Codes
                            </AccordionItemButton>
                        </AccordionItemHeading>
                        <AccordionItemPanel>
                            <div className="flex justify-between items-center mt-2">
                                <p className="sampleLabel">Color Code</p>
                                <input className="textInput" type="text" value={colorCode} onChange={(e) => setColorCode(e.target.value)} />
                            </div>
                            <div className="flex justify-between items-center mt-2">
                                <p className="sampleLabel">Engine Code</p>
                                <input className="textInput" type="text" value={engineCode} onChange={(e) => { setEngineCode(e.target.value) }} />
                            </div>
                            <div className="flex justify-between items-center mt-2">
                                <p className="sampleLabel">Trunk key code</p>
                                <input className="textInput" type="text" value={trunkKeyCode} onChange={(e) => { setTrunkKeyCode(e.target.value) }} />
                            </div>
                            <div className="flex justify-between items-center mt-2">
                                <p className="sampleLabel">Keyless code</p>
                                <input className="textInput" type="text" value={keylessCode} onChange={(e) => { setKeylessCode(e.target.value) }} />
                            </div>
                            <div className="flex justify-between items-center mt-2">
                                <p className="sampleLabel">Radio code</p>
                                <input className="textInput" type="text" value={radioCode} onChange={(e) => { setRadioCode(e.target.value) }} />
                            </div>
                            <div className="flex justify-between items-center mt-2">
                                <p className="sampleLabel">Wheel lock code</p>
                                <input className="textInput" type="text" value={wheellockCode} onChange={(e) => setWheellockCode(e.target.value)} />
                            </div>
                            <div className="flex justify-between items-center mt-2">
                                <p className="sampleLabel">Dealer code</p>
                                <input className="textInput" type="text" value={dealerCode} onChange={(e) => setDealerCode(e.target.value)} />
                            </div>
                        </AccordionItemPanel>
                    </AccordionItem>
                    <hr />
                    <AccordionItem>
                        <AccordionItemHeading>
                            <AccordionItemButton>
                                Dates
                            </AccordionItemButton>
                        </AccordionItemHeading>
                        <AccordionItemPanel>
                            <div className="flex justify-between items-center mt-2">
                                <p className="sampleLabel">Date in inventory</p>
                                <input className="textInput" type="datetime-local" value={inventoryDate ? inventoryDate.toISOString().slice(0, -8) : ''} onChange={(e) => setInventoryDate(new Date(e.target.value))} />
                            </div>
                            <div className="flex justify-between items-center mt-2">
                                <p className="sampleLabel">Date in service</p>
                                <input className="textInput" type="datetime-local" value={serviceDate ? serviceDate.toISOString().slice(0, -8) : ''} onChange={(e) => setServiceDate(new Date(e.target.value))} />
                            </div>
                        </AccordionItemPanel>
                    </AccordionItem>
                    <hr />
                    <AccordionItem>
                        <AccordionItemHeading>
                            <AccordionItemButton>
                                Accounts
                            </AccordionItemButton>
                        </AccordionItemHeading>
                        <AccordionItemPanel>
                            <div className="flex justify-between items-center mt-2">
                                <p className="sampleLabel">Sale Account</p>
                                {/* <select className="textInput" value={salesAccount} onChange={(e) => { setSalesAccount(e.target.value) }}>
                                    <option>Select sale account</option>
                                    <option>6</option>
                                </select> */}
                                <input className="textInput" type="text" value={salesAccount} onChange={(e) => setSalesAccount(e.target.value)} />
                            </div>
                            <div className="flex justify-between items-center mt-2">
                                <p className="sampleLabel">Inventory Account</p>
                                {/* <select className="textInput" value={inventoryAccount} onChange={(e) => { setInventoryAccount(e.target.value) }}>
                                    <option>Select inventory account</option>
                                    <option>6</option>
                                </select> */}
                                <input className="textInput" type="text" value={inventoryAccount} onChange={(e) => setInventoryAccount(e.target.value)} />
                            </div>
                        </AccordionItemPanel>
                    </AccordionItem>
                    <hr />
                    <AccordionItem>
                        <AccordionItemHeading>
                            <AccordionItemButton>
                                Warranty
                            </AccordionItemButton>
                        </AccordionItemHeading>
                        <AccordionItemPanel>
                            <div className="flex justify-between items-center mt-2">
                                <p className="sampleLabel">Months</p>
                                <input className="textInput" type="number" value={warranty} onChange={(e) => { setWarranty(parseInt(e.target.value)) }} />
                            </div>
                            <div className="flex justify-between items-center mt-2">
                                <p className="sampleLabel">Miles</p>
                                <input className="textInput" type="number" value={miles} onChange={(e) => setMiles(parseInt(e.target.value))} />
                            </div>
                            <div className="flex justify-between items-center mt-2">
                                <p className="sampleLabel">Deductible</p>
                                <input className="textInput" type="number" value={deductible} onChange={(e) => setDeductible(parseInt(e.target.value))} />
                            </div>
                        </AccordionItemPanel>
                    </AccordionItem>
                    <hr />
                    <AccordionItem>
                        <AccordionItemHeading>
                            <AccordionItemButton>
                                Dealership Internal
                            </AccordionItemButton>
                        </AccordionItemHeading>
                        <AccordionItemPanel>
                            <div className="flex justify-between items-center mt-2">
                                <p className="sampleLabel">Demo Name</p>
                                <input className="textInput" type="text" value={demoName} onChange={(e) => { setDemoName(e.target.value) }} />
                            </div>
                        </AccordionItemPanel>
                    </AccordionItem>
                </Accordion>
            </div>
            <div className="modal-footer">
                <div className="flex justify-around items-center mt-4">
                    <button className="button-6" onClick={sendData}>
                        Send
                    </button>
                    {/* <button className="button-6">Cancel</button> */}
                    <button className="button-6" onClick={printData}>Print</button>
                </div>
            </div>
            {modalIsOpen ? (<CommonModal isOpen={modalIsOpen} title={modalTitle} onAlert={() => setModalIsOpen(false)} onClose={() => setModalIsOpen(false)} onHideData={undefined} />) : null }
        </div>
    )
}

export default AddVehicles