import React, { useMemo, useState, useEffect } from "react";
import { HideData } from "./dashboard";
import { GridDataState } from "../utils/store";
import { useSelector } from "react-redux";
import './styles.css'

interface Props {
    onHideDataChange: HideData
}

interface GridDataItem {
    key: string;
    hidden: boolean
}

const ColumnChooser = ({ onHideDataChange }: Props) => {
    const [isMounted, setIsMounted] = useState(false);
    const [isInitialized, setIsInitialized] = useState(false);
    const initialGridData = useSelector((state: GridDataState) => state.gridData.gridData);
    const [gridData, setGridData] = useState<GridDataItem[]>([]);

    useEffect(() => {
        setIsMounted(true);
        return () => {
            setIsMounted(false);
            setIsInitialized(false);
        }
    }, []);

    useEffect(() => {
        if (!isMounted) return;

        const processGridData = () => {
            if (Array.isArray(initialGridData) && initialGridData.length > 0) {
                const typedData: GridDataItem[] = initialGridData.filter(item => item && item.key || item.field).map(item => ({
                    key: String(item.key || item.field),
                    hidden: Boolean(item.hidden)
                }));

                if (typedData.length > 0) {
                    setGridData(typedData);
                    setIsInitialized(true);
                }
            }
        };

        processGridData();

        if (!isInitialized && initialGridData === undefined) {
            const retryTimer = setTimeout(processGridData, 100);
            return () => clearTimeout(retryTimer)
        }
    }, [initialGridData, isMounted, isInitialized])

    const sortedGridData = useMemo(() => {
        if (!gridData.length) return [];

        return [...gridData].sort((a, b) => {
            if (!a.key || !b.key) return 0;
            return a.key.toLowerCase().localeCompare(b.key.toLowerCase());
        });
    }, [gridData])

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>, key: string) => {
        const isChecked = event.target.checked;
        const updatedGridData: GridDataItem[] = gridData.map((item) => {
            if (item.key === key) {
                return { key: item.key, hidden: !isChecked };
            }
            return {
                key: item.key,
                hidden: item.hidden
            }
        });
        setGridData(updatedGridData);

        let data = {
            'key': key,
            'hidden': !isChecked
        };

        onHideDataChange(data);
    };

    if (!sortedGridData.length) {
        return null;
    }

    if (!isInitialized || !sortedGridData.length) {
        <div className="max-w-sm rounded overflow-hidden shadow-lg">
            <div className="px-6 py-4">
                <label className="sampleLabel text-graphite">{isInitialized ? 'No columns available' : 'Loading columns...'}</label>
            </div>
        </div>
    }

    return (
        <div className="max-w-sm rounded overflow-hidden shadow-lg">
            <div className="px-6 py-4">
                {sortedGridData.map((item: { key: string, hidden: boolean }, index: React.ChangeEvent<HTMLInputElement> | React.Key | null | undefined) => (
                    <div key={`column-${item.key}-${index}`} className="flex items-center capitalize">
                        <input
                            type="checkbox"
                            id={`printButton-${index}`}
                            checked={!item.hidden}
                            onChange={(e) => handleChange(e, item.key)}
                        />&nbsp;&nbsp;
                        <label htmlFor={`printButton-${item.key}-${index}`} className="sampleLabel text-graphite">{item.key}</label>
                    </div>
                ))}
            </div>
        </div>
    )
}

export default ColumnChooser